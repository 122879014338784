import React from "react"
import { graphql } from "gatsby"
import PageTypes from "@yumgmbh/gatsby-theme-yum-components/src/components/page-types/_PageTypes"
import Blocks from "@yumgmbh/gatsby-theme-yum-components/src/components/blocks/_Blocks"

const CollectionTemplate = ({ data }) => {
  const entry = data.entry
  const content = JSON.parse(entry.content)
  const entries = data.collection

  const breadcrumbs = [
    {
      link: "/",
      link_text: "Startseite",
    },
    {
      link: "/magazin",
      link_text: "Magazin",
    },
  ]

  const contentBlocks = (content) => (
    <div>
      {content.body &&
        content.body.map((block) =>
          React.createElement(Blocks(block.component), {
            key: block._uid,
            block: block,
            type: block.component,
          })
        )}
    </div>
  )

  return React.createElement(
    PageTypes(content.component),
    {
      key: entry.uid,
      content: content,
      name: entry.name,
      type: content.component,
      entries: entries,
      breadcrumbs: breadcrumbs,
    },
    contentBlocks(content)
  )
}

export const query = graphql`
  query CollectionByTagQuery($id: String!, $tagList: [String]) {
    entry: storyblokEntry(id: { eq: $id }) {
      content
      name
      full_slug
    }
    collection: allStoryblokEntry(
      filter: {
        tag_list: { in: $tagList }
        field_component: { eq: "news_page" }
        full_slug: { regex: "/^magazin.*$/s" }
      }
      sort: { fields: first_published_at, order: DESC }
    ) {
      nodes {
        name
        full_slug
        content
        tag_list
        field_component
      }
    }
  }
`

export default CollectionTemplate
